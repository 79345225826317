import { defineStore } from "pinia";
import { type User } from '@/typings/interface';
import { auth, db } from "../../firebase-config";
import { serverTimestamp } from 'firebase/firestore';
import { useUserStore } from "./userStore";

export const useAuthStore = defineStore('auth', {

    state: () => ({
        authUser: null as User | null,

    }),

    getters: {
        user: (state) => state.authUser
    },

    actions: {
        async register(firstName: string, lastName: string, email: string, password: string) {
            return auth.createUserWithEmailAndPassword(email, password)
                .then((cred) => {
                    if(cred.user)
                    return db.collection('users').doc(cred.user.uid).set({
                        email: email,
                        first_name: firstName,
                        last_name: lastName,
                        role: 'user',
                        approved: 'pending',
                        completed_exercises: [],
                        completed_simulations: [],
                        available_exercises: [],
                        favorite_videos: [],
                        active_exercise: '',
                        created_at: serverTimestamp()
                    })
                })
        },

        async login(email: string, password: string) {
            return auth.signInWithEmailAndPassword(email, password);
        },

        async resetPassword (email: string) {
            return auth.sendPasswordResetEmail(email);
        },

        async logOut() {
            this.authUser = null;
            return auth.signOut();
        },

        async getCurrentUser(): Promise<User | null> {
            return new Promise((resolve) => {
                return auth.onAuthStateChanged((user) => {
                    if (user) {
                        try{
                            db.collection('users').doc(user.uid).onSnapshot((doc) => {
                                if (doc.exists && doc.data()) {
                                    this.authUser = doc.data() as User;
                                    resolve(doc.data() as User);
                                    useUserStore().fetchComments();
                                }
                            })
                        }
                        catch(error) {
                            console.error("Error getting user data:", error);
                            resolve(null);
                        }
                    } else {
                        console.log('No user logged in');
                        resolve(null);
                    }
                });
            });
        }


    }
})