import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { auth } from '../../firebase-config';
import { useAuthStore } from '@/store/authStore';

declare module 'vue-router' {
    interface RouteMeta {
        cluster?: string | undefined,
        role?: string | undefined,
        title?: string | undefined,
        hideFooter?: boolean,
        hideHeader?: boolean
    }
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/SignInView.vue'),
        meta: {
            title: 'inloggen'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/auth/RegisterView.vue'),
        meta: {
            title: 'registreren'
        }
    },
    {
        path: '/overzicht',
        name: 'overview',
        component: () => import('../views/UsersOverviewView.vue'),
        meta: {
            title: 'overzicht',
            requiresAuth: true
        }
    },
    {
        path: '/meldingen',
        name: 'notifications',
        component: () => import('../views/NotificationsView.vue'),
        meta: {
            title: 'meldingen',
            requiresAuth: true
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/ContactView.vue'),
        meta: {
            title: 'contacteren'
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/PrivacyView.vue'),
        meta: {
            title: 'privacybeleid',
            hideHeader: true,
            hideFooter: true
        }
    },{
        path: '/cookies',
        name: 'cookies',
        component: () => import('../views/CookieView.vue'),
        meta: {
            title: 'cookiebeleid',
            hideFooter: true,
            hideHeader: true
        }
    },
    {
        path: '/deelnemen',
        name: 'participate',
        component: () => import('../views/ParticipateView.vue'),
        meta: { 
            title: 'deelnemen'
        }
    },
    {
        path: '/inschrijven-vormingstraject',
        name: 'subscribe',
        component: () => import('@/views/SubscribeView.vue'),
        meta: {
            title: 'inschrijven',
        }
    },
    {
        path: '/faq',
        name: 'Veelgestelde vragen',
        component: () => import('../views/FAQView.vue'),
        meta: {
            title: 'Veelgestelde vragen'
        }
    },
    {
        path: '/team',
        name: 'team',
        component: () => import('../views/TeamView.vue'),
        meta: {
            title: 'contacteren'
        }
    },
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: 'tacos',
            background: true
        }
    },
    {
        path: '/rollen',
        name: 'roles',
        component: () => import('@/views/roles/RolesInfoView.vue'),
        meta: {
            title: 'rollen',
            requiresAuth: true
        }
    },
    {
        path: '/rollen/edit',
        name: 'edit-roles',
        component: () => import('@/views/roles/EditRolesInfoView.vue'),
        meta: {
            title: 'rollen',
            role: 'admin',
            requiresAuth: true
        }
    },
    {
        path: '/oefeningen',
        name: 'exercises',
        component: () => import('../views/videobank/ExercisesView.vue'),
        meta: {
            'cluster': 'mix',
            title: 'oefeningen',
            requiresAuth: true
        }
    },
    {
        path: '/oefening/:id',
        name: 'exercise',
        component: () => import('../views/videobank/ExerciseView.vue'),
        meta: {
            'cluster': 'mix',
            title: 'oefening',
            hideFooter: true,
            requiresAuth: true
        }
    },
    {
        path: '/oefening/:id/overzicht',
        name: 'exercise-overview',
        component: () => import('@/views/videobank/CompletedExerciseOverviewView.vue'),
        meta: {
            requitesAuth: true,
            title: 'oefening overzicht'
        }
    },
    {
        path: '/oefening/aanmaken',
        name: 'create-exercise',
        component: () => import('../views/exercise-builders/CreateNewExerciseView.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin',
            title: 'oefening aanmaken',
            hideFooter: true,
        }
    },
    {
        path: '/oefening/:id/editen',
        name: 'edit-exercise',
        component: () => import('../views/exercise-builders/EditExerciseView.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin',
            title: 'oefening editen',
            hideFooter: true,
        }
    },

    {
        path: '/logboek',
        name: 'logbook',
        component: () => import('../views/LogbookView.vue'),
        meta: {
            requiresAuth: true,
            title: 'logboek',
            hideFooter: true,
        }
    },

    {
        path: '/simulatie/intro',
        name: 'simulation-intro',
        component: () => import('../views/simulation/SimulationsView.vue'),
        meta: {
            requiresAuth: true,
            title: 'simulatie intro',
            hideFooter: true
        }
    },
    {
        path: '/simulatie/:id',
        name: 'simulation',
        component: () => import('../views/simulation/SimulationView.vue'),
        meta: {
            requiresAuth: true,
            title: 'simulatie',
            hideFooter: true,
            hideHeader: true
        }
    },
    {
        path: '/registratie-in-behandeling',
        name: 'registraion-pending',
        component: () => import('../views/RegistraionPendingView.vue'),
        meta: {
            title: 'Registratie in behandeling'
        }
    },

    
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: () => import('../views/PagNotFoundView.vue'),
        meta: {
            title: 'Pagina niet gevonden'
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes, 
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 25, el: 'header' }
    },
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const adminRoute = isAdminRoute(to);
    const user = authStore.user || (await authStore.getCurrentUser());

    if (user && to.name === 'login' || user && to.name === 'register'){
        next({name: 'exercises'});
        return;
    }

    if (user && user.approved === 'true' && to.name === 'registraion-pending') {
        next({name: 'exercises'});
        return;
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (user) {
            if (user.approved === 'true' && !adminRoute || (adminRoute && user.role === 'admin')) {
                next();
            }
            else if (user.approved === 'pending') {
                next('/registratie-in-behandeling');
            } else {
                next('/niet-gevonden');
            }
        } else {
            next({ name: 'login' });
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
});

function isAdminRoute(route: RouteLocationNormalized) {
    return route && route.meta && route.meta.role === 'admin';
}

export default router
